<template>
  <v-container fluid style="margin-bottom: 100px">
    <v-row justify="center">
      <v-col cols="12" lg="10">
        <v-row>
          <v-col>
            <h2 class="mt-4 secondary--text">
              {{ `${ isEdit ? 'Editar' : isCopy ? 'Cópiar' : ''} Modelo de layout` }}
              <span v-if="isVisualization" class="visualization-text">(visualização)</span>
            </h2>
          </v-col>
          <v-col>
            <v-stepper value="2" style="background-color: transparent" class="elevation-0">
              <v-stepper-header>
                <v-stepper-step complete step="1">
                  Layout
                </v-stepper-step>
                <v-divider />
                <v-stepper-step step="2">
                  Colunas
                </v-stepper-step>
                <v-divider />
                <v-stepper-step step="3">
                  Transformações e Validações
                </v-stepper-step>
              </v-stepper-header>
            </v-stepper>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="center" class="mt-5">
      <v-col cols="12" lg="10">
        <v-row>
          <v-col cols="12" md="3">
            <label class="label">Nome do Layout</label>
            <v-text-field
              class="mt-2"
              v-model="layout.name"
              solo
              dense
              :readonly="isCopy ? false : true"
              :disabled="isCopy ? false : true"
              :autofocus="isCopy ? true : false"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12" lg="10">
        <v-row>
          <v-col cols="12" md="3">
            <label class="label">Tipo do Arquivo</label>
            <v-text-field
              class="mt-2"
              :value="layout.fileType"
              solo
              dense
              readonly
              disabled
            />
          </v-col>
          <v-col cols="12" md="3">
            <label class="label">Tipo do Layout</label>
            <v-text-field
              class="mt-2"
              :value="layout.layoutType === 'DELIMITED' ? 'Delimitado' : layout.layoutType === 'NONE' ? 'Nenhum' :  layout.layoutType === 'POSITIONAL' ? 'Posicional' : null"
              solo
              dense
              readonly
              disabled
            />
          </v-col>
          <v-col v-if="layout.delimiter" cols="12" md="3">
            <label class="label">Delimitador</label>
            <v-text-field
              class="mt-2"
              :value="layout.delimiter"
              solo
              dense
              readonly
              disabled
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-form ref="formValidation">
      <v-row justify="center">
        <v-col cols="12" lg="10" class="px-0">
          <LayoutColumnComponent :propsLayout="layout" :propsIsVisualization="isVisualization" @updateLayout="updateLayout"/>
        </v-col>
      </v-row>
    </v-form>

    <v-snackbar
      v-model="snackbar.show"
      timeout="10000"
      top
      elevation="24"
      :color="snackbar.type"
    >
      <div class="text-center">{{ snackbar.text }}</div>
      <template v-slot:action="{ attrs }">
        <v-icon
          text
          dark
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          close
        </v-icon>
      </template>
    </v-snackbar>

    <Footer
      @cancelar="onClickCancel()"
      @salvar="onClickSave()"
      @voltar="onClickBack()"
      :disabledBack="!isEdit && !isCopy"
      :disabledCancel="!isVisualization"
      cancelButtonLabel="Cancelar"
      saveButtonLabel="Avançar"
    />
  </v-container>
</template>

<script>
import LayoutService from '@/services-http/sdi/LayoutService';
import Footer from '@/components/Footer/Footer.vue';
import LayoutColumnComponent from '@/components/Layout/Columns/LayoutColumnComponent.vue';
import { mapGetters, mapMutations } from 'vuex';

export default ({
  components: {
    Footer,
    LayoutColumnComponent,
  },

  data: () => ({
    snackbar: {
      show: false,
      type: '',
      text: '',
    },
    isEdit: false,
    isCopy: false,
    isVisualization: false,

    layout: {},

    name: '',
    order: null,
    size: '',
    type: 'ALPHANUMERIC',
    transformations: [],
    validations: [],
  }),

  mounted() {
    if (sessionStorage.getItem('layout')) {
      this.loadSessionStorage();
    }
    if (this.layout.id && !sessionStorage.getItem('visualization')) {
      this.isEdit = this.$route.name === 'ColumnEditConfigs';
      this.isCopy = this.$route.name === 'ColumnCopyConfigs';
    }
    if (sessionStorage.getItem('visualization')) {
      this.isVisualization = true;
    }
  },

  methods: {
    ...mapMutations({
      setLayout: 'layout-import/setLayout',
    }),
    loadSessionStorage() {
      this.layout = JSON.parse(sessionStorage.getItem('layout'));
      this.layout.columns = this.layout.columns.sort((a, b) => (a.order > b.order ? 1 : -1));
      this.setLayout(this.layout)
    },
    onClickBack() {
      this.redirectRouter('back');
    },
    onClickCancel() {
      this.redirectRouter('cancel');
    },
    onClickSave() {
      if (this.layout.columns && this.layout.columns.length > 0) {
        if (this.layout.name) {
          if (this.$refs.formValidation.validate()) {
            sessionStorage.setItem('layout', JSON.stringify(this.layoutData));
            this.redirectRouter('save');
          } else {
            this.customizeSnackbarMessage('error', 'Verifique os campos obrigatórios');
          }
        } else {
          this.customizeSnackbarMessage('error', 'Por favor preencha o nome do layout');
        }
      } else {
        this.customizeSnackbarMessage('error', 'Adicione colunas para prosseguir');
      }
    },
    updateLayout(layout) {
      this.layout = layout;
      this.setLayout(layout);
    },
    customizeSnackbarMessage(type, text) {
      this.snackbar.show = true;
      this.snackbar.type = type;
      this.snackbar.text = text;
    },
    redirectRouter(type) {
      if (type === 'save') {
        if (this.isEdit) {
          this.$router.push(this.$route.query.financialGroupId ? { name: 'TransformationEditConfigs', query: { financialGroupId: Number(this.$route.query.financialGroupId) } } : { name: 'TransformationEditConfigs' });
        } else if (this.isCopy) {
          this.$router.push(this.$route.query.financialGroupId ? { name: 'TransformationCopyConfigs', query: { financialGroupId: Number(this.$route.query.financialGroupId) } } : { name: 'TransformationCopyConfigs' });
        } else {
          this.$router.push(this.$route.query.financialGroupId ? { name: 'TransformationConfigs', query: { financialGroupId: Number(this.$route.query.financialGroupId) } } : { name: 'TransformationConfigs' });
        }
      } else if (type === 'back') {
        if (this.isEdit || this.isCopy) {
          this.$router.push(this.$route.query.financialGroupId ? { name: 'LayoutModel', query: { financialGroupId: Number(this.$route.query.financialGroupId) } } : { name: 'LayoutModel' });
        } else if (this.isVisualization) {
          sessionStorage.removeItem('layout');
          this.$router.push(this.$route.query.financialGroupId ? { name: 'LayoutImportConfigs', query: { financialGroupId: Number(this.$route.query.financialGroupId) } } : { name: 'LayoutImportConfigs' });
        } else {
          sessionStorage.setItem('layout', JSON.stringify(this.layout));
          this.$router.push(this.$route.query.financialGroupId ? { name: 'InitialConfigs', query: { financialGroupId: Number(this.$route.query.financialGroupId) } } : { name: 'InitialConfigs' });
        }
      } else if (type === 'cancel') {
        if (sessionStorage.getItem('redirectToImport')) {
          sessionStorage.removeItem('visualization');
          sessionStorage.removeItem('layout');
          this.$router.push(this.$route.query.financialGroupId ? { name: 'LayoutImportConfigs', query: { financialGroupId: Number(this.$route.query.financialGroupId) } } : { name: 'LayoutImportConfigs' });
        } else {
          this.$router.push(this.$route.query.financialGroupId ? { name: 'LayoutModel', query: { financialGroupId: Number(this.$route.query.financialGroupId) } } : { name: 'LayoutModel' });
        }
      }
    },
  },

  computed: {
    ...mapGetters({
      layoutData: 'layout-import/layout',
    }),
  },

  created() {
    this.layoutService = new LayoutService();
  },
});
</script>

<style scoped>
.visualization-text {
  color: #797787;
  font-weight: bold;
  font-size: 1.1rem;
  padding: 0 1rem;
}
</style>
